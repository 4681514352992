import React from "react"

import styled from "@emotion/styled"
import { FieldConfig, useField } from "formik"
import { border, lighten, padding } from "polished"

import { Input, theme, toEm } from "./index"

const InputError = styled.p({
  color: theme.colors.red,
})

export const UnlabeledFormikField: React.FC<
  {
    label: string
    required?: boolean
    disabled?: boolean
    placeholder?: string
    className?: string
    autoComplete?: string
    autoFocus?: boolean
    // as?: string
  } & FieldConfig
> = ({ label: _unused, className, ...props }) => {
  const [field, meta] = useField(props)
  const id = `field-${field.name}`
  return (
    <div className={className}>
      {/* {props.as && props.as === "textarea" ? (
        <Textarea
          id={id}
          {...field}
          type={props.type}
          required={props.required}
          disabled={props.disabled}
          placeholder={props.placeholder}
        />
      ) : ( */}
      <Input
        id={id}
        css={{ width: "100%" }}
        {...field}
        type={props.type}
        required={props.required}
        disabled={props.disabled}
        placeholder={props.placeholder}
        autoComplete={props.autoComplete}
        autoFocus={props.autoFocus}
      />
      {/* )} */}
      {meta.touched && meta.error ? (
        <InputError css={{ marginTop: ".5em" }}>{meta.error}</InputError>
      ) : null}
    </div>
  )
}

const Label = styled.label()
const Textarea = styled.textarea({
  color: theme.colors.black,
  fontSize: "1em",
  borderRadius: toEm(12),
  boxShadow: `0 ${toEm(3)} ${toEm(6)} rgba(0, 0, 0, 0.16)`,
  ...padding(toEm(20), toEm(24)),
  ...border(toEm(2), "solid", "#F6F6F6"),
  WebkitAppearance: "none",
  "::placeholder": {
    color: "rgba(61, 61, 61, 0.5)",
  },
  ":disabled": {
    backgroundColor: "rgba(246,246,246,1)",
  },
})

export const FormikField: React.FC<
  {
    label: string
    required?: boolean
    disabled?: boolean
    placeholder?: string
    className?: string
    autoComplete?: string
    as?: string
  } & FieldConfig
> = ({ label, className, ...props }) => {
  const [field, meta] = useField(props)
  const id = `field-${field.name}`
  return (
    <div className={className}>
      <Label htmlFor={id} css={{ display: "block", marginBottom: toEm(8) }}>
        {label}
        {props.required ? "*" : ""}
      </Label>
      {props.as && props.as === "textarea" ? (
        <Textarea
          css={{ display: "block", width: "100%" }}
          id={id}
          {...field}
          required={props.required}
          disabled={props.disabled}
          placeholder={props.placeholder}
        />
      ) : (
        <Input
          css={{ display: "block", width: "100%" }}
          id={id}
          {...field}
          type={props.type}
          required={props.required}
          disabled={props.disabled}
          placeholder={props.placeholder}
          autoComplete={props.autoComplete}
        />
      )}
      {meta.touched && meta.error ? (
        <InputError>{meta.error}</InputError>
      ) : null}
    </div>
  )
}

const ListItem = styled.div({
  ...padding(toEm(16), toEm(24)),
  backgroundColor: theme.colors.grey,
  borderRadius: toEm(10),
})

const ListItemLabel = styled(ListItem.withComponent("label"))({
  display: "block",
})

export const OptionListItem: React.FC<{ className?: string } & FieldConfig> = ({
  children,
  className,
  ...props
}) => {
  const [field] = useField({ ...props, type: "radio" })

  const id = `id-${props.value}`

  return (
    <div
      css={{
        userSelect: "none",
        'input[type="radio"]:focus + label': {
          backgroundColor: lighten(0.05, theme.colors.green),
        },
      }}
    >
      <input
        id={id}
        css={{ position: "absolute", opacity: 0 }}
        type="radio"
        {...field}
      />
      <ListItemLabel
        role="radio"
        aria-checked={field.checked}
        className={className}
        htmlFor={id}
        css={[
          {
            cursor: "pointer",
            ".vat": {
              color: theme.colors.green,
            },
          },
          field.checked
            ? {
                backgroundColor: theme.colors.green,
                color: theme.colors.white,
                ".vat": {
                  color: theme.colors.white,
                },
              }
            : {},
        ]}
      >
        {children}
      </ListItemLabel>
    </div>
  )
}
